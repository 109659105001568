













































































































import { defineComponent } from "@vue/composition-api";
import useAuthPermission from "@/use/authPermissions";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    modulesEnabled: {
      type: Object,
      required: true,
    },
  },

  components: {
    MParticipantItems: () =>
      import("@/components/molecules/eventSidebar/m-participant-items.vue"),
    MCompanyItems: () =>
      import("@/components/molecules/eventSidebar/m-company-items.vue"),
    MEventProgramItems: () =>
      import("@/components/molecules/eventSidebar/m-event-program-items.vue"),
    MAbstractsItems: () =>
      import("@/components/molecules/eventSidebar/m-abstracts-items.vue"),
    MPosterSessionItems: () =>
      import("@/components/molecules/eventSidebar/m-poster-session-items.vue"),
    MSurveyItems: () =>
      import("@/components/molecules/eventSidebar/m-survey-items.vue"),
    MPrintItems: () =>
      import("@/components/molecules/eventSidebar/m-print-items.vue"),
    MReportItems: () =>
      import("@/components/molecules/eventSidebar/m-report-items.vue"),
    MAccessControlItems: () =>
      import("@/components/molecules/eventSidebar/m-access-control-items.vue"),
    MInfoItems: () =>
      import("@/components/molecules/eventSidebar/m-info-items.vue"),
    MConfigurationItems: () =>
      import("@/components/molecules/eventSidebar/m-configuration-items.vue"),
    MWebsiteItems: () =>
      import("@/components/molecules/eventSidebar/m-website-items.vue"),
    MRegistrationModule: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-registration-module.vue"
      ),
    MServicesModule: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-services-module.vue"
      ),
    MAccommodationModule: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-accommodation-module.vue"
      ),
    MLocationModule: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-location-module.vue"
      ),
    MSurveyModule: () =>
      import("@/components/molecules/eventSidebar/modules/m-survey-module.vue"),
    MBudgetModule: () =>
      import("@/components/molecules/eventSidebar/modules/m-budget-module.vue"),
    MMessageModule: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-message-module.vue"
      ),
    MAutomaticMessageModule: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-automatic-message-module.vue"
      ),
    MReceptionConfig: () =>
      import(
        "@/components/molecules/eventSidebar/modules/m-reception-config.vue"
      ),
  },

  setup(_, { root }) {
    const {
      adminAccess,

      participantsList,
      participantsManagement,
      participantsInvoice,

      companiesList,
      companiesManagement,

      eventProgramManagement,

      abstractsLists,
      abstractsManagement,

      posterSessionList,

      surveysList,

      accessControlView,
      accessControlManagement,

      printList,
      printManagement,

      reportsManagement,

      infoAccess,

      eventConfig,
      eventConfigData,
      eventConfigDiscounts,
      eventConfigParticipantGroups,

      registrationModule,
      registrationModuleData,
      registrationModuleFee,

      accomodationModule,
      accomodationModuleData,
      accomodationModuleHotels,

      additionalServicesModule,
      additionalServicesModuleData,
      additionalServicesModuleForms,
      additionalServicesModuleServices,

      partnersModule,
      eventProgramModule,
      surveysModule,
      companiesModule,
      receptionModule,
      autoMessagesModule,

      lecturersList,
      lecturersManagement,
    } = useAuthPermission({ root });

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    return {
      adminAccess,

      participantsList,
      participantsManagement,
      participantsInvoice,

      companiesList,
      companiesManagement,

      eventProgramManagement,

      abstractsLists,
      abstractsManagement,

      posterSessionList,

      surveysList,

      accessControlView,
      accessControlManagement,

      printList,
      printManagement,

      reportsManagement,

      infoAccess,

      eventConfig,
      eventConfigData,
      eventConfigDiscounts,
      eventConfigParticipantGroups,

      registrationModule,
      registrationModuleData,
      registrationModuleFee,

      accomodationModule,
      accomodationModuleData,
      accomodationModuleHotels,

      additionalServicesModule,
      additionalServicesModuleData,
      additionalServicesModuleForms,
      additionalServicesModuleServices,

      partnersModule,
      eventProgramModule,
      surveysModule,
      companiesModule,
      receptionModule,
      autoMessagesModule,

      lecturersList,
      lecturersManagement,

      hasAccessTo,
    };
  },
});
